import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import VimeoPlayer from '../../components/vimeoplayer/vimeoplayer'
import Callus from '../../components/callus/callus'
import ButtonBlocks from "../../components/buttonblocks/buttonblocks"
import ButtonBlock from "../../components/buttonblock/buttonblock"

const IndexPage = () => (
  <Layout>

      <SEO title="Step 1 - Rules - The Ultimate Guide To Getting Google Reviews - Five Star Review System" />

      <VimeoPlayer videoId='388148373' />

      <Callus />

      <ButtonBlocks>
          <ButtonBlock
            text='Free 15-Minute Consult'
            link='//bestlocalreviewstraining.as.me/?appointmentType=12872884'
            description='Schedule a free 15-minute consult and tell us what you want to do. We’ll get you moving in the right direction.'
          />
      </ButtonBlocks>

  </Layout>
)

export default IndexPage
